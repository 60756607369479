<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-if="currentItem.id===null" 
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                block
                class="custom-btn"
            >   REGULAR
                <v-icon small class="ma-3" color="white">mdi-plus-outline</v-icon>
            </v-btn>
            
            <v-btn
                v-else 
                v-bind="attrs"
                v-on="on"
                icon
                class="custom-btn"
            > 
                <v-icon small class="ma-3" color="white">mdi-book-edit-outline</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-4 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                REGULAR
            </v-card-title>
            <v-card-text>
                <div id="imprimir">
                    <v-card class="pa-2 ma-0 mt-4">
                        <v-row dense>
                            <v-col dense :cols="12">
                            <span class="text-caption">NOME:</span> {{ currentItem.nome }}
                            </v-col>
                            <v-col dense :cols="12">
                                <span class="text-caption">CONTATO:</span>  {{ currentItem.contato }}
                            </v-col>
                            <v-col dense :cols="12">
                                <span class="text-caption">Tempo de diabetes:</span>  {{ currentItem.tempo_diabetes }} - anos
                            </v-col>
                            <v-col dense :cols="12"><span class="text-caption">IDADE:</span> {{ calcularIdade(currentItem.dt_nascimento) }}</v-col>
                            <v-col dense :cols="6"><span class="text-caption">Glicemia Capilar:</span> {{ currentItem.glicemia_capilar }} mg/dl</v-col>
                            <v-col dense :cols="6"><span class="text-caption">Ref: Em jejum: 99 mg/dl <br>após alimentação: até 140 mg/dl</span></v-col>
                            <v-col dense :cols="6"><span class="text-caption">Glicemia Plasmática:</span> {{ currentItem.glicemia_plasmatica }} mg/dl</v-col>
                            <v-col dense :cols="6"><span class="text-caption">Ref: Em jejum: 70 a 99 mg/dl</span></v-col>
                            <v-col dense :cols="6"><span class="text-caption">Hemoglobina Glicada:</span> {{ currentItem.hemoglobina_glicada }}%</v-col>
                            <v-col dense :cols="6">
                                <span class="text-caption">
                                    Ref: Em jejum: até 5,7% - Normal <br>de 5,8 a 6,4 - pré-diabético <br> de 6,5% - provável diabético
                                </span></v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD s/alteração:</span> {{ sim_nao(currentItem.od_sem_alteracao) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD rdnp leve:</span> {{ sim_nao(currentItem.od_rdnp_leve) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD rdnp moderada:</span> {{ sim_nao(currentItem.od_rdnp_moderada) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD rdnp grave/ m grave:</span> {{ sim_nao(currentItem.od_rdnp_grave_m_grave) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD rdp:</span> {{ sim_nao(currentItem.od_rdp) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OD edema macular:</span> {{ sim_nao(currentItem.od_edema_macular) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS sem alteracao:</span> {{ sim_nao(currentItem.os_sem_alteracao) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS rdnp leve:</span> {{ sim_nao(currentItem.os_rdnp_leve) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS rdnp moderada:</span> {{ sim_nao(currentItem.os_rdnp_moderada) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS rdnp grave/ m grave:</span> {{ sim_nao(currentItem.os_rdnp_grave_m_grave) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS rdp:</span> {{ sim_nao(currentItem.os_rdp) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">OS edema_macular:</span> {{ sim_nao(currentItem.os_edema_macular) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">catarata:</span> {{ sim_nao(currentItem.catarata) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">glaucoma:</span> {{ sim_nao(currentItem.glaucoma) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">outras:</span> {{ sim_nao(currentItem.outras) }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">observacoes doencas olhos:</span> {{ currentItem.observacoes_doencas_olhos }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">observacoes:</span> {{ currentItem.observacoes }}</v-col>
                        </v-row>
                        <v-col dense :cols="12"><span class="text-caption">orientar:</span>
                             
                             <v-radio-group row label="OUTRAS" 
                                required :rules="[e=>!!e||'Campo obrigatório!']" 
                                v-model="currentItem.orientar">
                                    <v-radio value="1" label="SIM"></v-radio> 
                                    <v-radio value="0" label="NÃO"></v-radio> 
                            </v-radio-group>
                        </v-col>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-row dense>
                    <v-col dense :cols="12">
                        <v-btn :loading="loading" outlined block @click="post_lista()"><v-icon>mdi-content-save</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-row min-height="20">
                <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import httpApiService from '@/http'
    export default{
        name:'Paciente',
        props:{currentItem:{type:Object}},
        data:()=>({
            dialog:false,
            loading:false,
            msg:'',
            sexos:[],
            orgaos_expedidores:[],
            estados:[],
            frequencia_hemoglobina_glicada_diaria:[],
            cidades:[],
            problema_pes:[],
        }),
        methods:{
            imprimir() {
                const element = document.getElementById('imprimir');
                window.print(element);
            },
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            sim_nao(val) {
                if(val===null || val==='' ){
                    return 'N/A'
                }
                return val?'SIM':'NÃO';
            },
            async post_lista(){
                this.loading = true
                if(!this.$refs.form.validate()) {
                    this.loading=false 
                    return false
                }

                const response = await httpApiService.patch(`mutirao-sus/paciente/${this.currentItem.id}/`,this.currentItem)
                
                if(response.status===200){
                    this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                    this.$emit('atualizaRegular')
                }else{
                    for(const i in response.response.data){
                        this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                    }    
                }
                this.loading=false
                
            },
        },
        mounted(){
        },
       
    }
</script>
<style>

.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>